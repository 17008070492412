import { template as template_58ca0459d6d0432d8cb2be89926fe467 } from "@ember/template-compiler";
const WelcomeHeader = template_58ca0459d6d0432d8cb2be89926fe467(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;

import { template as template_821b7008f0104d33b34fdf3183d1d87e } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { modifier } from "ember-modifier";
import highlightSearch from "discourse/lib/highlight-search";
export default class HighlightSearch extends Component {
    highlight = modifier((element)=>{
        highlightSearch(element, this.args.highlight);
    });
    static{
        template_821b7008f0104d33b34fdf3183d1d87e(`
    <span {{this.highlight}}>{{yield}}</span>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}

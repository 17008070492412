import { template as template_e496327483e5464d854e40d15e7d05d7 } from "@ember/template-compiler";
import icon from "discourse/helpers/d-icon";
const ActionList = template_e496327483e5464d854e40d15e7d05d7(`
  {{#if @postNumbers}}
    <div class="post-actions" ...attributes>
      {{icon @icon}}
      {{#each @postNumbers as |postNumber|}}
        <a href="{{@topic.url}}/{{postNumber}}">#{{postNumber}}</a>
      {{/each}}
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActionList;

import { template as template_752dea3da27a459b94b228f13a12ff9d } from "@ember/template-compiler";
const FKControlMenuContainer = template_752dea3da27a459b94b228f13a12ff9d(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;

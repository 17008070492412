import { template as template_b815c9113e4d43a0b731084abe576e5c } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const PostMenuCopyLinkButton = template_b815c9113e4d43a0b731084abe576e5c(`
  <DButton
    class="post-action-menu__copy-link"
    ...attributes
    @action={{@buttonActions.copyLink}}
    @icon="d-post-share"
    @label={{if @showLabel "post.controls.copy_action"}}
    @title="post.controls.copy_title"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PostMenuCopyLinkButton;

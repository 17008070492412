import { template as template_b3a8b657fb0e412b9fdfdd478030b7a8 } from "@ember/template-compiler";
const FKLabel = template_b3a8b657fb0e412b9fdfdd478030b7a8(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
